import React from "react"
import { graphql } from "gatsby"

// Components
import { Layout } from "../components/layout"
import { PageWrapper } from "../components/page"
import { H1 } from "../components/typography"
import { PortableText } from "../components/content"

const Page = ({ data }) => {
  const doc = data.sanityPage

  return (
    <Layout>
      <PageWrapper>
        <H1>{doc.title}</H1>
        {doc._rawBody && <PortableText blocks={doc._rawBody} />}
      </PageWrapper>
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($id: String!) {
    sanityPage(id: { eq: $id }) {
      id
      title
      _rawBody(resolveReferences: { maxDepth: 5 })
      slug {
        current
      }
    }
  }
`

export default Page
